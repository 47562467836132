@value main, weiterButton, btn, table, icon, red, orange from '../../styles/basicVariables.module.css';

.main > .btn.weiterButton.csvBtn {
	font-size: 0.85em;
	padding: 5px;
	display: inline-block;
	margin: 10px;
}

.main .vorgangContent {
	min-height: 50vh;
}

.vorgaengeListe {
	table-layout: fixed;
}
.vorgaengeListe button {
	width: 100%;
}
.vorgaengeListe td,
.vorgaengeListe th {
	border-left: none;
	border-right: none;
}
.vorgaengeListe th {
	cursor: pointer;
}
.vorgaengeListe .normalColumn.small {
	width: 100px;
	max-width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.vorgaengeListe .normalColumn a {
	color: black;
}
.vorgaengeListe .normalColumn span.warning {
	color: orange;
}
.vorgaengeListe .normalColumn span.deadlinePassed {
	color: red;
}
.vorgaengeListe .expandableHeading td {
	padding: 30px 15px 15px;
}
.vorgaengeListe .expandableHeading td:first-child {
	padding-left: 15px;
}
.vorgaengeListe .expandableHeading span {
	margin-right: 20px;
	display: inline-block;
}
.vorgaengeListe td.massActions {
	text-align: center;
	padding: 0 5px;
}

.yearSelector {
	float: right;
	padding: 8px;
}

.yearSelector select {
	display: inline-block;
	width: auto;
	padding: 2px 30px 2px 10px;
	margin-bottom: 0;
}
